// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { RootState } from "../../redux/reducers";

// Material UI
import { Box, Button, Divider, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  getGridNumericOperators,
  getGridStringOperators,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
// Fetch
import { getAll } from "../../fetchs/get";

// Interface
import { ILot } from "../../interface/lot";
import { IProject } from "../../interface/project";
import { ITaskCost } from "../../interface/task";

// Components
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { Switch } from "@mui/material";

let defaultLotCosts = {
  C: 0,
  RTUD: 0,
  RTUI: 0,
  QS: 0,
  QC: 0,
  QE: 0,
  RS: 0,
  RC: 0,
  RE: 0,
  D: 0,
  PC: 0,
  A: 0,
};

// Formatage des nombres au format français avec le symbole €
const formatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
});

const formatter2 = new Intl.NumberFormat("fr-FR", {
  style: "decimal",
});

export default function ProjectCostsSummary({
  project,
  setLotId,
  lotId,
  setOpenModal,
}: {
  project: IProject;
  setLotId: any;
  lotId: string;
  setOpenModal: Function;
}) {
  function CustomToolbar(project: string) {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <GridToolbarColumnsButton sx={{ mx: 1 }} />
          <GridToolbarFilterButton sx={{ mx: 1 }} />
          <GridToolbarExport
            sx={{ mx: 1 }}
            csvOptions={{ delimiter: ';', utf8WithBom: true, fileName: "Liste des lots du projet " + project }}
            printOptions={{ hideToolbar: true, fileName: "Something"}}
          />
        </div>
        <Button
          variant="contained"
          color="tertiary"
          sx={{ mx: 1, mt: "5px" }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Ajouter un lot
        </Button>
      </GridToolbarContainer>
    );
  }
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [ArrayLot, setArrayLot] = useState<ILot[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);

  let navigate = useNavigate();
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    if (lotId) setLotId(lotId);
  }, [lotId, setLotId]);

  const processLots = (lots: ILot[]) => {
    if (lots) {
      lots.forEach((lot: ILot) => {
        if (lot.total === undefined) {
          lot.total = 0;
          lot.lotCosts = defaultLotCosts;
        }
      });
      setArrayLot(lots);
    }
  };

  const getLotsProject = () => {
    if (token)
      getAll(
        token,
        `/projects/${project._id}/lots`,
        processLots,
        () => dispatch(sessionEnd()),
        (err: any) => {
          navigate(`/projets/`);
          dispatch(showSnackbar("Erreur lors de la récupération des lots : " + err?.error, "error"));
        }
      );
  };

  useEffect(() => {
    getLotsProject();
  }, [project.lots]);

  const selectedLots = ArrayLot.filter(lot => selectedRows.includes(lot._id));

  // Ici l'on map sur les élèments présent en BDD, ce qui pose problème car lorsqu'on ajoute un lot depuis cette page aucune requête d'insertion en BDD ne se fait, on update juste project.lots. L'affichage de ne s'actualise pas il faudrait pour y remédier faire la requête d'insertion en BDD au moment de l'ajout du lot sur cette page ou mapper sur un tableau différents prenant en compte les nouveaux élèments dans project.lots
  let totaux = {
    preparatory: 0,
    preparatoryWriteTest: 0,
    preparatoryExecuteTest: 0,
    preparatoryPiloting: 0,
    preparatoryRealDev: 0,
    preparatoryRealInfra: 0,
    preparatoryAccompaniement: 0,
  };

  let rows: GridRowsProp = ArrayLot.map((lot: ILot) => {
    let preparatory = lot.total;
    let preparatoryWriteTest = lot.lotCosts.QC!;
    let preparatoryExecuteTest = lot.lotCosts.QE!;
    let preparatoryPiloting = lot.lotCosts.PC!;
    let preparatoryRealDev = lot.lotCosts.RTUD!;
    let preparatoryRealInfra = lot.lotCosts.RTUI!;
    let preparatoryAccompaniement = lot.lotCosts.A!;

    if (selectedLots.some(selectedLot => selectedLot._id === lot._id)) {
      totaux.preparatory += preparatory;
      totaux.preparatoryPiloting += preparatoryPiloting;
      totaux.preparatoryExecuteTest += preparatoryExecuteTest;
      totaux.preparatoryWriteTest += preparatoryWriteTest;
      totaux.preparatoryRealDev += preparatoryRealDev;
      totaux.preparatoryRealInfra += preparatoryRealInfra;
      totaux.preparatoryAccompaniement += preparatoryAccompaniement;
    }

    return {
      id: lot._id,
      name: lot.name,
      preparatory: preparatory,
      preparatoryWriteTest: preparatoryWriteTest,
      preparatoryExecuteTest: preparatoryExecuteTest,
      preparatoryPiloting: preparatoryPiloting,
      preparatoryRealDev: preparatoryRealDev,
      preparatoryRealInfra: preparatoryRealInfra,
      preparatoryAccompaniement: preparatoryAccompaniement,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom du lot",
      flex: 2,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatory",
      headerName: "Coût Total du lot",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryRealDev",
      headerName: "Real/Dev",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryRealInfra",
      headerName: "Real/Infra",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryWriteTest",
      headerName: "Ecriture Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryExecuteTest",
      headerName: "Exécution Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryPiloting",
      headerName: "Pilotage",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryAccompaniement",
      headerName: "Accompagnement",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      valueFormatter: (params) => formatter.format(params.value as number),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    }
  ];

  const columnsDays: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom du lot",
      flex: 2,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryDays",
      headerName: "Jours Total",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryRealDevDays",
      headerName: "Jours dev/con",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryRealInfraDays",
      headerName: "Jours Infra",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryWriteTestDays",
      headerName: "Jours Ecri-Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryExecuteTestDays",
      headerName: "Jours Exec-Test",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryPilotingDays",
      headerName: "Jours Pilotage",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    },
    {
      field: "preparatoryAccompagnementDays",
      headerName: "Jours Accomp",
      flex: 2,
      filterOperators: getGridNumericOperators(),
      type: "number",
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.colDef.headerName}
        </div>
      )
    }
  ];

  /*Lorsque l'on clique sur la cellules contenant un lot, on set le lot permettant ainsi de l'afficher*/
  const handleChange = (lot: any) => {
    if (setLotId) {
      setLotId(lot.id);
    }
  };

  const TextOneLineEllipsis: SxProps<Theme> = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };



  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const switchView = () => {
    setIsSwitchChecked(!isSwitchChecked)
    convertValues()
  };


  const convertValues = () => {
    if (isSwitchChecked) {
      ArrayLot.forEach(lot => {
        const tasksCharges = lot.tasksCharges;
        let key: keyof ITaskCost;
        let charge = 0;
        // on ne prend pas en compte la garantie
        for (key in tasksCharges) {
          if (key != "G") {
            charge += tasksCharges[key]!;
          }
        }
      })
    }
    else {
    }
  };

  type TableauDaysType = {
    preparatoryDays: number
    preparatoryWriteTestDays: number
    preparatoryExecuteTestDays: number
    preparatoryPilotingDays: number
    preparatoryRealDevDays: number
    preparatoryRealInfraDays: number
    preparatoryAccompagnementDays: number
  };

  let totauxDays = {
    preparatoryDays: 0,
    preparatoryWriteTestDays: 0,
    preparatoryExecuteTestDays: 0,
    preparatoryPilotingDays: 0,
    preparatoryRealDevDays: 0,
    preparatoryRealInfraDays: 0,
    preparatoryAccompagnementDays: 0,
  };

  const [totauxDaysTableau, setTotauxDaysTableau] = useState<TableauDaysType>(totauxDays);

  function customRound(charge: number) {
    return (charge * 10 % 10 === 2.5 || charge * 10 % 10 === 7.5) ? Math.floor(charge * 2) / 2 : Math.round(charge * 2) / 2;
  };

  let rowsDays: GridRowsProp =
    ArrayLot.map((lot: ILot) => {
      const tasksCharges = lot.tasksCharges;
      const preparatoryDays = customRound((tasksCharges?.QC ?? 0) + (tasksCharges?.QE ?? 0) + (tasksCharges?.QS ?? 0) + (tasksCharges?.PC ?? 0) + (tasksCharges?.RTUD ?? 0) + (tasksCharges?.RTUI ?? 0) + (tasksCharges?.A ?? 0) + (tasksCharges?.RS ?? 0) + (tasksCharges?.RC ?? 0) + (tasksCharges?.RE ?? 0) + (tasksCharges?.C ?? 0) + (tasksCharges?.D ?? 0));
      const preparatoryWriteTestDays = tasksCharges?.QC ?? 0;
      const preparatoryExecuteTestDays = tasksCharges?.QE ?? 0;
      const preparatoryPilotingDays = tasksCharges?.PC ?? 0;
      const preparatoryRealDevDays = (tasksCharges?.RTUD ?? 0) + (tasksCharges?.C ?? 0);
      const preparatoryRealInfraDays = tasksCharges?.RTUI ?? 0;
      const preparatoryAccompagnementDays = tasksCharges?.A ?? 0;

      if (selectedLots.some(selectedLot => selectedLot._id === lot._id)) {
        totauxDays.preparatoryDays += preparatoryDays;
        totauxDays.preparatoryPilotingDays += preparatoryPilotingDays;
        totauxDays.preparatoryExecuteTestDays += preparatoryExecuteTestDays;
        totauxDays.preparatoryWriteTestDays += preparatoryWriteTestDays;
        totauxDays.preparatoryRealDevDays += preparatoryRealDevDays;
        totauxDays.preparatoryRealInfraDays += preparatoryRealInfraDays;
        totauxDays.preparatoryAccompagnementDays += preparatoryAccompagnementDays;
      }

      return {
        id: lot._id,
        name: lot.name,
        preparatoryDays,
        preparatoryWriteTestDays,
        preparatoryExecuteTestDays,
        preparatoryPilotingDays,
        preparatoryRealDevDays,
        preparatoryRealInfraDays,
        preparatoryAccompagnementDays,
      };
    })

  console.log(rowsDays)

  useEffect(() => {
    if (!isSwitchChecked) {
      setTotauxDaysTableau(totauxDays)
      return
    }

    rowsDays.map((tasksCharges) => {
      setTotauxDaysTableau(prevValue => {
        return {
          preparatoryDays: prevValue.preparatoryDays + tasksCharges.preparatoryDays,
          preparatoryWriteTestDays: prevValue.preparatoryWriteTestDays + tasksCharges.preparatoryWriteTestDays,
          preparatoryExecuteTestDays: prevValue.preparatoryExecuteTestDays + tasksCharges.preparatoryExecuteTestDays,
          preparatoryPilotingDays: prevValue.preparatoryPilotingDays + tasksCharges.preparatoryPilotingDays,
          preparatoryRealDevDays: prevValue.preparatoryRealDevDays + tasksCharges.preparatoryRealDevDays,
          preparatoryRealInfraDays: prevValue.preparatoryRealInfraDays + tasksCharges.preparatoryRealInfraDays,
          preparatoryAccompagnementDays: prevValue.preparatoryAccompagnementDays + tasksCharges.preparatoryAccompagnementDays,
        }
      }
      )
    })
  }, [isSwitchChecked])

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  const [pageSize] = useState(8);

  const loadMoreLots = () => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      pageSize: prevPaginationModel.pageSize + ArrayLot.length,
    }));
  };
  return (
    <>
      <div style={{ marginLeft: '10px' }} className="container-projectCostsSummary">
        <div style={{ textAlign: "center" }}>
          <h3 style={{ marginBottom: '9px' }} className="projectCostsSummary__title">Synthèse du projet : Vue coût <Switch {...label} onClick={() => switchView()} checked={isSwitchChecked} /> Vue charge</h3>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            border: 'rgba(0,0,0,0.1) solid 1px',
            borderRadius: 1,
            padding: 2,
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: 16 }}>
              Totaux :
            </Typography>
          </Box>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Totaux" : "Coût Total du projet"}

              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryDays) : formatter.format(totaux.preparatory)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Dev/Conception" : "Real/Dev"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryRealDevDays) : formatter.format(totaux.preparatoryRealDev)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Infra" : "Real/Infra"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryRealInfraDays) : formatter.format(totaux.preparatoryRealInfra)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Ecri-Test" : "Ecriture test"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryWriteTestDays) : formatter.format(totaux.preparatoryWriteTest)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Exéc-Test" : "Exécution Test"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryExecuteTestDays) : formatter.format(totaux.preparatoryExecuteTest)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Pilotage" : "Pilotage"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryPilotingDays) : formatter.format(totaux.preparatoryPiloting)}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1, textAlign: 'center', minWidth: 0, padding: '0 10px' }}>
              <Typography noWrap>
                {isSwitchChecked ? "Jours Accomp" : "Accompagnement"}
              </Typography>
              <Typography noWrap style={{ color: 'gray' }}>
                {isSwitchChecked ? formatter2.format(totauxDays.preparatoryAccompagnementDays) : formatter.format(totaux.preparatoryAccompaniement)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div style={{ height: "67vh", width: "100%", marginTop: "15px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DataGrid
            disableRowSelectionOnClick={true}
            rows={isSwitchChecked ? rowsDays : rows}
            columns={isSwitchChecked ? columnsDays : columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[pageSize]}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              },
              flex: 1,
              width: '100%',
            }}
            onCellClick={(params) => {
              if (params.field === '__check__') {
                return;
              }
              handleChange(params);
            }}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: '10px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
            onClick={loadMoreLots}
          >
            Voir plus de lot
          </Button>
        </div>
      </div>
    </>
  );
}